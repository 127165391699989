export enum EntityStoreKey {
  mainView = 'mainView',
}

export interface IMainViewState {
  sidebarOpen: boolean;
  breadcrumbs: BreadcrumbItem[];
  headerHidden: boolean;
  headerMenuContext: HeaderMenuContext;
}

export interface BreadcrumbItem {
  url: string;
  title: string;
  active: boolean;
}

export enum HeaderMenuContext {
  Logged = 'logged',
  NotLogged = 'not_logged',
}

export type HeaderMenuContextType = HeaderMenuContext.Logged | HeaderMenuContext.NotLogged;
