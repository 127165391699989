import { HttpErrorResponse } from '@angular/common/http';

import { createSelector } from '@ngrx/store';

import type { Ability } from '@recruitee/abilities-types';
import { memoizeSelector, createSingleEntityStore, ngrxEvent, pipeState } from '@recruitee/ngrx';

import { EntityStoreKey, ReferralsPortalProfile, ReferralsPortalProfileUpdate } from '../types';

const { actions, selectors, reducer, namespace } =
  createSingleEntityStore<ReferralsPortalProfile>()(EntityStoreKey.profile, {
    customActions: adapter => ({
      updateProfile: (state, payload: ReferralsPortalProfileUpdate) => adapter.markAsPending(state),
      updateProfileSuccess: (state, payload: ReferralsPortalProfile) => {
        const { preferredLanguage, ...data } = payload;
        return pipeState(adapter.update(state, data), next => adapter.markAsLoaded(next));
      },
      updateProfileFail: ngrxEvent<HttpErrorResponse>(),
    }),
  });

const abilitiesHasOne = memoizeSelector((ability: Ability) =>
  createSelector(selectors.data, function abilitiesHasOne(state: ReferralsPortalProfile) {
    return (state?.abilities || []).includes(ability);
  }),
);

const profileSelectors = { ...selectors, abilitiesHasOne };

export {
  actions as ProfileEntityActions,
  reducer as profileReducer,
  profileSelectors,
  namespace as ProfileNamespace,
};
