import { createSelector } from '@ngrx/store';

import { createSingleEntityStore } from '@recruitee/ngrx';

import {
  BreadcrumbItem,
  EntityStoreKey,
  IMainViewState,
  HeaderMenuContextType,
  HeaderMenuContext,
} from '../types';

const { actions, selectors, reducer, namespace } = createSingleEntityStore<IMainViewState>()(
  EntityStoreKey.mainView,
  {
    customActions: adapter => ({
      init: state =>
        adapter.update(state, {
          sidebarOpen: false,
          breadcrumbs: [],
          headerHidden: false,
          headerMenuContext: HeaderMenuContext.Logged,
        }),
      toggleSidebar: (state, payload?: boolean) =>
        adapter.update(state, {
          sidebarOpen: payload !== undefined ? payload : !state.data?.sidebarOpen,
        }),
      setBreadcrumbs: (state, payload: BreadcrumbItem[]) =>
        adapter.update(state, {
          breadcrumbs: payload,
        }),
      toggleHeader: (state, payload: boolean) =>
        adapter.update(state, {
          headerHidden: payload,
        }),
      setHeaderMenuContext: (state, payload: HeaderMenuContextType) =>
        adapter.update(state, {
          headerMenuContext: payload,
        }),
    }),
  },
);

const isMainHeaderHiddenSelector = createSelector(
  selectors.data,
  function isMainHeaderHidden(state: IMainViewState) {
    return state.headerHidden && !state.sidebarOpen;
  },
);

const mainViewSelectors = {
  ...selectors,
  isMainHeaderHiddenSelector,
};

export {
  actions as MainViewEntityActions,
  reducer as mainViewReducer,
  mainViewSelectors,
  namespace as MainViewNamespace,
};
