import { RECRUITEE_BACKEND } from '@recruitee/environment/production';

import { ReferralsEnvironment } from './interface';

export const environment: ReferralsEnvironment = {
  environmentName: 'production',
  angularProdMode: true,
  analytics: {
    isEnabled: true,
    key: 'EnUZQG6dCWZ329EWjVWV6lyF6ec92cZJ',
    requiredFeatureFlag: false,
  },
  i18n: {
    phraseAppProjectId: '33b93fa8c87183f3257609a570160087',
    editorEnabled: true,
  },
  hmr: false,
  protocol: RECRUITEE_BACKEND.protocol,
  domain: RECRUITEE_BACKEND.domain,
  authUrl: RECRUITEE_BACKEND.authUrl,
  apiUrl: RECRUITEE_BACKEND.apiUrl,
  appUrl: RECRUITEE_BACKEND.appUrl,
  webAppUrl: RECRUITEE_BACKEND.webAppUrl,
  landingPageUrl: RECRUITEE_BACKEND.appUrl,
  recaptchaSiteKey: '6LdznlAUAAAAAGaYOdwE_id-rzKrOvsc-a-LHRGl',
  websocketUrl: 'wss://api.recruitee.com/referral_portal_socket',
  resolveSubdomainFromHost: true,
  sentry_dsn: 'https://6f06cd03a6384f0f969607be397ea68d@o18167.ingest.sentry.io/1440983',
};
