import { Injectable } from '@angular/core';

import { AnalyticsBase } from '@recruitee/analytics';
import { OfferStatus } from '@recruitee/offers-types';

@Injectable({ providedIn: 'root' })
export class ReferralsPortalAnalytics extends AnalyticsBase {
  public readonly namespace = 'ReferralHub';
  public readonly requireViewContext = false;

  public trackJobsPageViews(): void {
    this.track('ViewedJobsPage', {});
  }

  public trackJobOpen(
    isVisibleReward: boolean,
    offerStatus: OfferStatus.Internal | OfferStatus.Published,
  ): void {
    this.track('OpenJob', { isVisibleReward, offerStatus });
  }

  public trackReferralFormSubmit(): void {
    this.track('SubmitReferral', {});
  }

  public trackViewedProfile(): void {
    this.track('Viewed Profile Referrals Page', {});
  }

  public trackViewedReferralsPage(): void {
    this.track('Viewed Referrals Page', {});
  }

  public trackViewedHowItWorksPage(): void {
    this.track('Viewed How it works Page', {});
  }

  public trackMakeReferralButtonClick(viewContext: string): void {
    this.track('MakeReferral', { viewContext });
  }
}
