import { SupportedTranslations } from '@recruitee/i18n';
import { ThemeName } from '@recruitee/theme';

export enum EntityStoreKey {
  profile = 'profile',
}
export interface ReferralsPortalProfile {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl: string;
  avatarThumbUrl: string;
  isAdmin: boolean;
  referralIntroUsed: boolean;
  preferredLanguage: SupportedTranslations;
  recommendReupload: boolean;
  abilities: string[];
  preferences?: { theme: ThemeName };
}

export interface ReferralsPortalProfileUpdate
  extends Pick<ReferralsPortalProfile, 'firstName' | 'lastName' | 'email'> {
  avatar?: string;
}
