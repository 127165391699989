@if (breadcrumbs && breadcrumbs.length) {
  <div class="breadcrumbs-wrap">
    <div class="breadcrumbs-container">
      @for (
        breadcrumb of breadcrumbs;
        track trackByValue(index, breadcrumb);
        let index = $index;
        let last = $last
      ) {
        <div
          [class.active]="breadcrumb.active"
          (click)="!breadcrumb.active ? navigate(breadcrumb.url) : null"
          class="breadcrumb-item"
        >
          <div class="title">
            <rt-truncate>{{ breadcrumb.title }}</rt-truncate>
          </div>
          @if (!last) {
            <rt-icon name="arrow-drop-down"></rt-icon>
          }
        </div>
      }
    </div>
  </div>
}
