import { IAppConfig } from '@recruitee/common';
import { LanguageConfig } from '@recruitee/i18n';

import { environment } from '../environments/environment';

export const appConfig: IAppConfig = {
  authUrl: environment.authUrl + '/',
  apiUrl: environment.apiUrl + '/referral_portal',
  appUrl: environment.appUrl + '/',
  webAppUrl: environment.webAppUrl + '/',
  hasHttps: environment.protocol === 'https',
  recaptchaSiteKey: environment.recaptchaSiteKey,
  isE2e: !!window.Cypress,
};

export const ExternalUrl = {
  RecruiteeDashboard: environment.webAppUrl + '/',
  RecruiteeProfile: `${environment.webAppUrl}/#/settings/profile`,
  RecruiteeLandingPage: environment.landingPageUrl,
  LinkedInDataArchiveRequestPage: 'https://www.linkedin.com/psettings/member-data',
  WalktroughVideo: 'https://www.youtube.com/watch?v=WtEnzVFdt-E',
  Footer: 'https://support.recruitee.com/collections/2061100',
};
export type IExternalUrl = typeof ExternalUrl;

export const languageConfig: LanguageConfig = {
  defaultLanguage: 'en',
  languages: ['en', 'nl', 'de', 'fr', 'fi', 'sv'],
};
