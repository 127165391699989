import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { filterBoolean, trackByValue } from '@recruitee/common';
import { IconComponent, IconColorDirective, TruncateComponent } from '@recruitee/user-interface';

import { mainViewSelectors } from '../../../features/main-view/data/store/main-view.store';
import { BreadcrumbItem, IMainViewState } from '../../../features/main-view/data/types';

@Component({
  selector: 'rtr-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.less'],
  standalone: true,
  imports: [TruncateComponent, IconColorDirective, IconComponent],
})
export class BreadcrumbsComponent {
  public breadcrumbs: BreadcrumbItem[];
  public trackByValue = trackByValue;

  public mainViewState$: Observable<IMainViewState> = this.store
    .select(mainViewSelectors.data)
    .pipe(filterBoolean);
  private ngOnDestroy$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.mainViewState$
      .pipe(
        map(state => state.breadcrumbs),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(breadcrumbs => {
        this.breadcrumbs = breadcrumbs;
        this.cdRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public navigate(url: string): void {
    this.router.navigate([url]);
  }
}
